::selection {
  background: #2f4959;
  color: #ff6022;
}

::-moz-selection {
  background: #2f4959;
  color: #ff6022;
}

body {
  overflow-x: hidden !important;
}

.chakra-select {
  padding-left: 16px !important;
}

.chakra-select__icon-wrapper {
  opacity: 1 !important;
}

.blog_post_img {
  border-radius: 10px;
}

.blog-post-img-container {
  width: 60% !important;
  margin: 0 auto !important;
  padding: 2.5vh 0 !important;
}

.chakra-switch > span {
  background: #737373;
}

.chakra-switch > span[data-focus] {
  background: #5a5959;
  box-shadow: 0 0 0 3px rgba(116, 198, 158, 0.5) !important;
}

.chakra-switch > span[data-checked] {
  background: #74c69e;
}

.edit-contract-img-container > div > div > img,
.contract-details-img-container > div > div > img,
.main-contract-details-img-container > div > img,
.ws-contract-img-container > div > img {
  border-radius: 10px !important;
}

th {
  font-size: 18px !important;
  font-family: Poppins, sans-serif !important;
}

th > span > svg {
  display: inline-block;
}

tr:nth-child(even) {
  background-color: #f6f6f6;
}

tr:nth-child(odd) {
  background-color: #d6d6d6;
}

thead > tr {
  background-color: #f6f6f6 !important;
}

.table-container {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px;
}

.uppy * {
  font-family: "Lato", sans-serif;
}

.uppy-Dashboard-FileCard-preview {
  background-color: #74c69e !important;
}

.uppy-Dashboard-FileCard-actions {
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
}

.uppy-c-btn-primary {
  background-color: #ff6022 !important;
}

.uppy-c-btn-primary:hover {
  background-color: #e64000 !important;
}

.uppy-c-btn-primary:focus {
  box-shadow: none !important;
}

.cropper-point {
  background-color: #31c5f4 !important;
}

.uppy-DashboardContent-back {
  color: #2f4959 !important;
}

.uppy-c-btn-link {
  color: #737373 !important;
}

.uppy-c-btn-link:hover,
.uppy-DashboardContent-back:hover {
  color: #122430 !important;
}

.uppy-c-textInput:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.table-img-container > div {
  border-radius: 5px !important;
  display: block !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 480px) {
  .blog-post-img-container {
    width: 85% !important;
  }
}

#toast-box,
#toast-success {
  background-color: #45a375 !important;
  border-radius: 5px !important;
}

#toast-warning {
  background-color: #e64000 !important;
  border-radius: 5px !important;
}

/* Sentry error submission embed form */
.sentry-error-embed * {
  transition: 0.25s all !important;
}
.sentry-error-embed label,
.sentry-error-embed button,
.sentry-error-embed a,
.sentry-error-embed textarea {
  font-family: "Lato", sans-serif;
}
.sentry-error-embed h2,
.sentry-error-embed p,
.sentry-error-embed p span {
  font-family: "Poppins", sans-serif;
}

.sentry-error-embed .btn {
  margin-left: auto !important;
  background: #ff6022 !important;
  border: none !important;
  border-radius: 10px !important;
}
.sentry-error-embed .btn:hover {
  background: #e64000 !important;
}

.sentry-error-embed .close {
  border-radius: 10px !important;
  border: 2px solid !important;
  border-color: #d6d6d6 !important;
  padding: 10px 15px !important;
}
.sentry-error-embed .close:hover {
  background-color: #737373 !important;
  color: white !important;
  text-decoration: none !important;
}

.sentry-error-embed .form-submit {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
}

.sentry-error-embed .powered-by {
  display: none !important;
}

.sentry-error-embed {
  border-radius: 10px !important;
}

.message-left {
  text-align: right;
  position: relative;
  left: 17%;
}

.message-right {
  text-align: left;
  position: relative;
  right: 15%;
}

.message-block {
  display: inline-block;
  min-width: 1em;
  border-radius: 10px;
  padding-right: 0.5em;
  padding-left: 0.5em;
  padding-top: 0.1em;
  padding-bottom: 0.3em;
}

#cb-container {
  overflow-y: hidden !important;
}

.friend-request > .rce-citem > .rce-citem-avatar {
  margin: 0;
  padding: 0;
}

.friend-request > .rce-citem > .rce-citem-body {
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f0f2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5959;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #423f40;
}

.rounded-image {
  border-radius: 50%;
}
.app__messenger {
  min-width: 80vw !important;
  min-height: 75vh !important;
  margin: auto !important;
  z-index: 5 !important;
}
.close-chat-button {
  position: fixed !important;
  top: 12vh;
  left: 8vw;
  z-index: 6;
  max-width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: none;
}

.friend-request-number {
  position: relative;
  left: -1.2vw;
  top: 0.6vw;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rounded-image-container > div {
  border-radius: 5px;
}

div.app__launcher {
  display: none;
}

.btn-border-radius {
  border-radius: 5px !important;
}

path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}
.svg {
  width: 800px;
  height: 800px;
}

.drawn-path:hover {
  opacity: 1.0;
        filter: alpha(opacity=100);
        cursor: inherit

}
/* Icons Displayed in a Circle */
.ciclegraph {
  position: relative;
  width: 500px;
  height: 500px;
  margin: calc(100px / 2 + 0px);
}

.ciclegraph:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px * 2);
  height: calc(100% - 2px * 2);
  border-radius: 50%;
}

.ciclegraph .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: calc(-100px / 2);

}

.icon-text{
  position: relative;
  vertical-align: bottom;
  margin-top: 110%;
}

.center-rei-link-icon {
        position: absolute;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        left: 50%;
        top: 50%;
        margin-left: -20px;
        margin-top: -20px;

    }
