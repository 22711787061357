.progressBar {
  border-radius: 5px;
  background: #d9d9d9 !important;
}

.progressBar > div {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #74c69e !important;
}
